import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        redirect: '/gym',
        meta: {
            title: '首页',
            renderMenu: false,
            icon: 'CreditCardOutlined',
        },
    },
    {
        path: '/record',
        name: 'record',
        meta: {
            title: '健身记录',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Record.vue'),
        children: []
    },
    {
        path: '/test',
        name: 'test',
        meta: {
            title: '体测记录',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/BodyTest.vue'),
        children: []
    },
    {
        path: '/poster',
        name: 'poster',
        meta: {
            title: '海报',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Poster.vue'),
        children: []
    },

    {
        path: '/order',
        name: 'order',
        meta: {
            title: '订单',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        children: [
            {
                path: '/cardOrder',
                name: 'cardOrder',
                meta: {
                    title: '健身卡订单',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/CardOrder.vue'),
                children: [

                ]
            },
            {
                path: '/lessonOrder',
                name: 'lessonOrder',
                meta: {
                    title: '私教订单',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/LessonOrder.vue'),
                children: []
            },
            {
                path: '/productOrder',
                name: 'productOrder',
                meta: {
                    title: '商城订单',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/ProductOrder.vue'),
                children: []
            },
            {
                path: '/groupOrder',
                name: 'groupOrder',
                meta: {
                    title: '团购订单',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/groupOrder.vue'),
                children: []
            },
        ]
    },
    {
        path: '/member',
        name: 'member',
        meta: {
            title: '会员',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Member.vue'),
        children: []
    },
    {
        path: '/tag',
        name: 'tag',
        meta: {
            title: '会员标签',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Tag.vue'),
        children: []
    },
    {
        path: '/card',
        name: 'card',
        meta: {
            title: '健身卡',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Card.vue'),
        children: []
    },
    {
        path: '/coach',
        name: 'coach',
        meta: {
            title: '教练',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Coach.vue'),
        children: []
    },
    {
        path: '/program',
        name: 'program',
        meta: {
            title: '私教项目',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Program.vue'),
        children: []
    },

    {
        path: '/lessonRecord',
        name: 'lessonRecord',
        meta: {
            title: '私教记录',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/LessonRecord.vue'),
        children: []
    },
    {
        path: '/game',
        name: 'game',
        meta: {
            title: '比赛',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        children: [
            {
                path: '/gameDetail',
                name: 'gameDetail',
                meta: {
                    title: '比赛',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/Game.vue'),
                children: [

                ]
            },
            {
                path: '/gameRound',
                name: 'gameRound',
                meta: {
                    title: '一局比赛',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/GameRound.vue'),
                children: []
            },
            {
                path: '/gameRecord',
                name: 'gameRecord',
                meta: {
                    title: '比赛记录',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/GameRecord.vue'),
                children: []
            },
        ]
    },
    {
        path: '/cost',
        name: 'cost',
        meta: {
            title: '费用',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Cost.vue'),
        children: []
    },
    {
        path: '/gym',
        name: 'gym',
        meta: {
            title: '健身房',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Gym.vue'),
        children: []
    },
    {
        path: '/place',
        name: 'place',
        meta: {
            title: '空间',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Place.vue'),
        children: []
    },
    {
        path: '/device',
        name: 'device',
        meta: {
            title: '设备',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Device.vue'),
        children: []
    },
    {
        path: '/deviceRelationship',
        name: 'deviceRelationship',
        meta: {
            title: '设备关系',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/DeviceRelationship.vue'),
        children: []
    },
    {
        path: '/supplier',
        name: 'supplier',
        meta: {
            title: '供应商',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Supplier.vue'),
        children: []
    },
    {
        path: '/wall',
        name: 'wall',
        meta: {
            title: '照片墙审核',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Wall.vue'),
        children: []
    },
    {
        path: '/gift',
        name: 'gift',
        meta: {
            title: '礼品',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Gift.vue'),
        children: []
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            title: '商品',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Product.vue'),
        children: []
    },
    {
        path: '/group',
        name: 'group',
        meta: {
            title: '团购',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Group.vue'),
        children: []
    },
    {
        path: '/iot',
        name: 'iot',
        meta: {
            title: '物联网',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        children: [
            {
                path: '/IOTProduct',
                name: 'IOTProduct',
                meta: {
                    title: '产品',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/AliyunIOTProduct.vue'),
                children: []
            },
            {
                path: '/IOTDevice',
                name: 'IOTDevice',
                meta: {
                    title: '设备',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/AliyunIOTDevice.vue'),
                children: [

                ]
            },
            {
                path: '/tuyaProduct',
                name: 'tuyaProduct',
                meta: {
                    title: '涂鸦产品',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/TuyaIOTProduct.vue'),
                children: []
            },
            {
                path: '/tuyaDevice',
                name: 'tuyaDevice',
                meta: {
                    title: '涂鸦设备',
                    renderMenu: true,
                    icon: 'CreditCardOutlined'
                },
                component: () => import('@/pages/tuyaIOTDevice.vue'),
                children: [

                ]
            },
        ]
    },
    {
        path: '/setting',
        name: 'setting',
        meta: {
            title: '设置',
            renderMenu: true,
            icon: 'CreditCardOutlined'
        },
        component: () => import('@/pages/Setting.vue'),
        children: []
    },
    {
        path: '/front',
        name: '前端',
        meta: {
            renderMenu: false,
        },
        component: () => import('@/components/layout/FrontView.vue'),
        children: [
            {
                path: '/login',
                name: '登录',
                meta: {
                    icon: 'LoginOutlined',
                    view: 'blank',
                    target: '_blank',
                    cacheable: false,
                },
                component: () => import('@/pages/login'),
            },
            {
                path: '/gym',
                name: '首页',
                meta: {
                    view: 'blank',
                },
                component: () => import('@/pages/Gym.vue'),
            },
        ],
    },
    {
        path: '/403',
        name: '403',
        props: true,
        meta: {
            renderMenu: false,
        },
        component: () => import('@/pages/Exp403.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        props: true,
        meta: {
            icon: 'CreditCardOutlined',
            renderMenu: false,
            cacheable: false,
            _is404Page: true,
        },
        component: () => import('@/pages/Exp404.vue'),
    },
]

export default routes
